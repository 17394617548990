<template>
    <li class="system-module__item" :class="{'system-module__item--unavailable': !moduleData.is_access}">
        <div class="system-module__item-wrap">
            <div class="system-module__item-col system-module__item-col--left system-module__name-wrap"
                 v-bind:class="{'system-module__name-wrap_recommend': moduleData.is_recommend}">
                <div class="system-module__name">{{ moduleData.name }}</div>
                <div v-if="moduleData.is_hint && moduleData.text">
                    <button type="button" class="system-module__desc-btn" @click="showInfo">!</button>
                    <modal
                        :name="`module_${moduleData.id}`"
                        height="auto"
                        class="module__description"
                        id="module-description"
                        :shift-y="1"
                    >
                        <div class="modal-dialog module-description__inner">
                            <div class="modal-content">
                                <div class="module-description__header">
                                    <h3 class="block-title module-description__title">Модуль «{{ moduleData.name }}»</h3>
                                    <button type="button" class="modal__close-btn" @click="$modal.hide(`module_${moduleData.id}`)">x
                                    </button>
                                </div>
                                <div class="module-description__body">
                                    <div class="module-description__top-text">
                                        {{ moduleData.text }}
                                    </div>
                                    <div class="module-description__carousel" v-if="moduleData.image">
                                        <div class="module-description__slide">
                                            <img class="module-description__slide-img" :src="moduleData.image">
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="module-description__footer">-->
                                <!--<button type="button" class="module-description__pay-btn">-->
                                <!--Подключить за-->
                                <!--<span class="module-description__cost">8 990</span>-->
                                <!--&#8381; в месяц-->
                                <!--</button>-->
                                <!--</div>-->
                            </div>
                        </div>
                    </modal>
                </div>
            </div>
            <div class="system-module__item-col system-module__item-col--right d-flex align-items-center">
                <span class="system-module__cost d-flex" v-if="!moduleData.is_access">
                    {{moduleData.access_text}}
                </span>

                <div class="system-module__switch d-flex align-items-center" v-else-if="parseFloat(moduleData.price) === 0 && !moduleData.is_free">
                    <span class="system-module__cost">Бесплатно</span>
                    <toggle-button
                        :color="colors"
                        :height="20"
                        :width="40"
                        v-model="moduleData.is_connected"
                        :sync="true"
                        @change="toggleModule()"
                        class="m-0"
                    />
                </div>

                <div class="system-module__switch d-flex align-items-center" v-else-if="moduleData.is_free">
                    <span class="system-module__cost">Бесплатно</span>
                    <toggle-button
                        :color="colors"
                        :height="20"
                        :width="40"
                        v-model="moduleData.is_connected"
                        disabled
                        class="m-0"
                    />
                </div>

                <div class="system-module__switch d-flex align-items-center" v-else>
                    <span class="system-module__cost paid">
                        {{ moduleData.price }} &#8381;/мес
                    </span>
                    <toggle-button
                        :color="colors"
                        :height="20"
                        :width="40"
                        v-model="moduleData.is_connected"
                        :sync="true"
                        @change="toggleModule()"
                        class="m-0"
                    />
                </div>

                <button v-if="modulesSettings.includes(moduleData.type) && moduleData.is_access && moduleData.is_connected" @click="openSettings" type="button" class="system-module__set-btn">
                    <svg width="17" height="17" viewBox="0 0 17 17">
                        <g>
                            <g>
                                <path fill="#b3b3b3"
                                      d="M8.527 11.688a3.188 3.188 0 1 1 .001-6.377 3.188 3.188 0 0 1-.001 6.377zm7.85-4.426l-1.684-.28c-.122-.5-.3-.98-.533-1.426l1.078-1.336a.743.743 0 0 0-.023-.962l-.67-.754a.744.744 0 0 0-.952-.136l-1.44.906a6.335 6.335 0 0 0-2.124-.96L9.747.622A.743.743 0 0 0 9.013 0h-1.01a.74.74 0 0 0-.731.622l-.284 1.693c-.64.157-1.24.41-1.787.743l-1.37-.978a.744.744 0 0 0-.958.08l-.713.713a.744.744 0 0 0-.079.958l.98 1.373a6.347 6.347 0 0 0-.737 1.774l-1.703.284a.743.743 0 0 0-.62.734v1.008c0 .364.263.675.62.734l1.703.284a6.37 6.37 0 0 0 .564 1.483l-1.073 1.33a.744.744 0 0 0 .023.96l.67.754c.242.273.644.33.951.136l1.461-.918a6.327 6.327 0 0 0 2.068.918l.284 1.693a.74.74 0 0 0 .732.622h1.01a.743.743 0 0 0 .733-.622l.282-1.693a6.324 6.324 0 0 0 1.766-.73l1.427 1.019c.294.211.7.178.957-.08l.714-.713a.741.741 0 0 0 .078-.958l-1.016-1.424a6.325 6.325 0 0 0 .74-1.78l1.683-.28A.742.742 0 0 0 17 9.003V7.996a.743.743 0 0 0-.622-.734z"/>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    </li>
</template>

<script>
import session from '@/api/session';

export default {
    name: "Item",
    data() {
        return {
            modulesSettings: [`knowledge_base`, `documents`, `testing`, 'courses'],
            moduleData: this.module,
            colors: {checked: '#FBC04F', unchecked: '#EEEEEE'}
        }
    },
    props: {
        module: {
            type: Object
        }
    },
    methods: {
        async toggleModule(e) {
            console.log(this.moduleData.is_connected)
            const isConnected = !this.moduleData.is_connected;
            const {module} = this;
            const params = {
                module_id: this.moduleData.id
            };

            this.$swal({
                customClass: {
                    confirmButton: 'btn btn-lg btn-alt-success m-5',
                    cancelButton: 'btn btn-lg btn-alt-danger m-5'
                },
                title: module.name,
                text: `Желаете ${isConnected ? 'отключить' : 'подключить'}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Да',
                cancelButtonText: 'Отменить',
            }).then(async (value) => {
                if (value.value) {
                    const request = isConnected
                        ? await session.post(`/api/v1/module-management/remove/`, params)
                        : await session.post(`/api/v1/module-management/add/`, params);
                    this.$store.dispatch('navigation/initialize');
                } else {
                    this.moduleData.is_connected = !this.moduleData.is_connected
                    console.log(this.moduleData.is_connected)
                }
            });
        },
        showInfo() {
            this.$modal.show(`module_${this.moduleData.id}`);
        },
        openSettings() {
            /* Динамический импорт нужного модального окна */
            let module = () => import(`./modals/${this.moduleData.type}`);
            this.$modal.show(module, {
                user_id: this.user_id
            }, {
                name: this.moduleData.type,
                scrollable: true,
                height: 'auto',
                adaptive: true,
                maxWidth: 540,
                width: '100%',
                pivotY: 0,
            })
        }
    }
}
</script>

<style lang="scss">
@import "#sass/v-style";
</style>
